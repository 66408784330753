// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/_index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/_index.tsx");
  import.meta.hot.lastModified = "1708611543481.562";
}
// REMIX HMR END

"use client";
import { json } from "@remix-run/node";
import { useFetcher, useLoaderData } from "@remix-run/react";
import { Box, Button, SkeletonCircle, SkeletonText, VStack, useMediaQuery } from "@chakra-ui/react";
import { Frontpage, links as frontpageLinks } from "~/components/frontpage";
import { multiSearchQuery, parseHitToDealHit, parseHitToPromoHit } from "~/utils/MeiliSearch";

// or cloudflare/deno

import { DealHighlight, DealList, DealListSmall } from "~/components/dealList";
import { Footer } from "~/components/frontpage";
import { Navigation } from "~/components/navigation";
import { useEffect, useState } from "react";
import { Tabbar } from "~/components/navigation/Tabbar";
import { CommentListSmall } from "~/components/comment/CommentListSmall";
import { useSearchParams } from "@remix-run/react";
export const maxPage = 10;
export const links = () => [...frontpageLinks()];
export const headers = ({
  loaderHeaders
}) => ({
  "Cache-Control": loaderHeaders.get("Cache-Control") || "max-age=60, s-maxage=120"
});
export const sitemap = () => ({
  loc: `/`,
  changefreq: "always"
});
export async function action({
  request,
  params
}) {
  const data = await request.json();
  const filterNow = `timestamp <= ${Math.round(new Date().getTime() / 1000)} AND publish = true`;

  // console.log(filter);
  const hits = await multiSearchQuery([{
    limit: 20,
    offset: (data.page - 1) * 20,
    filter: filterNow,
    sort: ["timestamp:desc"]
  }]);
  return json({
    hits
  }, {
    status: 200,
    headers: {
      "Cache-Control": "max-age=60, stale-while-revalidate=30, s-maxage=120"
    }
  });
}
export async function loader({
  request,
  params
}) {
  let page = 1;
  const pageSize = 20;
  if (request.url) {
    const url = new URL(request.url);
    page = Number(url.searchParams.get("page") || 1);
  }
  const filterNow = `timestamp <= ${Math.round(new Date().getTime() / 1000)} AND publish = true`;
  const now = Math.floor(Date.now() / 1000);
  const filterPromo = `(startTime <= ${now} OR startTime = 0 ) AND (endTime > ${now} OR endTime = 0 ) AND type = "story" AND publish = true`;
  const filterPromoBanner = `(startTime <= ${now} OR startTime = 0 ) AND (endTime > ${now} OR endTime = 0 ) AND type = "banner" AND publish = true`;
  // console.log(filter);
  const hits = await multiSearchQuery([{
    limit: page <= maxPage ? page * pageSize : pageSize,
    offset: page <= maxPage ? 0 : (page - 1) * pageSize,
    filter: filterNow,
    sort: ["timestamp:desc"]
  }, {
    indexUid: "promotion",
    sort: ["position:asc", "createdAt:desc"],
    filter: filterPromo,
    limit: 100
  }, {
    indexUid: "promotion",
    sort: ["position:asc", "createdAt:desc"],
    filter: filterPromoBanner,
    limit: 100
  }]);
  return json({
    hits,
    page
  }, {
    status: 200,
    headers: {
      "Cache-Control": "max-age=60, stale-while-revalidate=30, s-maxage=120"
    }
  });
}
export default function Index() {
  _s();
  const data = useLoaderData();
  // console.log(data);
  // const [estimatedTotalHits, setEstimatedTotalHits] = React.useState(0);
  const [selectedTab, setSelectedTab] = useState(0);
  const [page, setPage] = useState(data.page);
  const [tab, setTab] = useState("neueste");
  const [root, setRoot] = useState("/deals/neueste");
  const [loading, setLoading] = useState(false);

  // console.log("getPlatform", Capacitor.getPlatform())
  const [hits, setHits] = useState(data.hits[0].hits.map(hit => parseHitToDealHit(hit)));
  const [searchParams, setSearchParams] = useSearchParams();
  const fetcher = useFetcher();
  const promoHits = data.hits[1].hits.map(hit => parseHitToPromoHit(hit));
  const promoBannerHits = data.hits[2].hits.map(hit => parseHitToPromoHit(hit));
  const estimatedTotalHits = data.hits[0]?.estimatedTotalHits ? data.hits[0]?.estimatedTotalHits : 0;
  useEffect(() => {
    setHits(data.hits[0].hits.map(hit => parseHitToDealHit(hit)));
    setPage(data.page);
    setLoading(false);
  }, [data.hits, data.page]);
  useEffect(() => {
    if (fetcher?.data) {
      console.log("fetcher");
      const fetcherData = fetcher.data;
      // const newHits = fetcherData.hits[0].hits.map((hit: any) =>
      //   parseHitToDealHit(hit)
      // );
      // setHits([...hits, ...newHits]);
      setLoading(false);
      setPage(page + 1);
    }
  }, [fetcher.data]);
  async function fetchData() {
    if (!loading && hits.length < estimatedTotalHits) {
      setLoading(true);

      // fetcher.submit(
      //   { page: page + 1 },
      //   { method: "post", encType: "application/json", action: "/?index" }
      // );

      const params = new URLSearchParams();
      params.set("page", String(page + 1));
      setSearchParams(params, {
        preventScrollReset: true
      });
    }
  }
  const [isLargerThan768] = useMediaQuery("(min-width: 992px)");
  const [showSidebar, setShowSidebar] = useState(true);
  useEffect(() => {
    setShowSidebar(isLargerThan768);
  }, [isLargerThan768]);
  return <>
      <Frontpage handleRefresh={() => {
      fetcher.load("/");
    }} header={<>
            <Navigation />

            {promoHits.length > 0 && <Box display={{
        base: "block",
        md: "none"
      }}>
                <DealHighlight items={promoHits} />
              </Box>}
          </>} main={<Box>
            <Tabbar selectedTab={selectedTab} tab={tab} root={root} />
            <Box px="3" mb={10}>
              {hits.length > 0 && <VStack>
                  <DealList deals={hits} promo={promoBannerHits} page={page} tab={tab} root={root} totalHits={estimatedTotalHits} showPagination={page > maxPage} fetchMoreData={fetchData} showLoading={fetcher.state == "loading"} />
                  {page <= maxPage && hits.length < estimatedTotalHits && <Button onClick={e => {
            fetchData();
          }} loadingText="Deals werden geladen..." isLoading={loading}>
                      Weitere Deals laden
                    </Button>}
                </VStack>}
              {hits.length == 0 && <VStack>
                  {Array.from({
            length: 20
          }, (_, i) => i + 1).map(value => <Box width={"100%"} key={value} padding="6" mb="4" boxShadow="lg">
                      <SkeletonCircle size="10" />
                      <SkeletonText mt="4" noOfLines={5} spacing="4" skeletonHeight="2" />
                    </Box>)}
                </VStack>}
            </Box>
          </Box>} sidebar={<Box mt={{
      base: "7",
      lg: "10"
    }} display={{
      base: "none",
      md: "block"
    }}>
            {promoHits.length > 0 && <Box mb={{
        base: "4",
        lg: "3"
      }}>
                <DealHighlight items={promoHits} />
              </Box>}
            <VStack>
              <DealListSmall title="Heißeste" type="hot" />
              <CommentListSmall title="Neueste Kommentare" />
            </VStack>
          </Box>} footer={<Footer />} />
    </>;
}
_s(Index, "tT972efrMZCtlugopxdpwePM7gg=", false, function () {
  return [useLoaderData, useSearchParams, useFetcher, useMediaQuery];
});
_c = Index;
var _c;
$RefreshReg$(_c, "Index");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;